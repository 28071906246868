import React from "react";
import Layout from "../../containers/Layout";
import { Contact } from "../../components";
import FaqsSection from "../../components/FaqsComponents/FaqsSection";

export default function Faqs() {
  return (
    <Layout title={"FAQs"}>
        <FaqsSection />
      <Contact />
    </Layout>
  );
}
