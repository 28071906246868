import React from "react";
import { Link } from "gatsby";
import GeneralImage from "../../../src/assets/images/faq-general.webp";
import ProductsImage from "../../../src/assets/images/faq-our-products.webp";
import RentalsImage from "../../../src/assets/images/faq-rentals.webp";
import PaymentsImage from "../../../src/assets/images/faq-payments.webp";
import FaqsContainer from "./FaqsContainer";

const faqs = [
  { id: 1, title: "General", image: GeneralImage, link: "/faqs/general" },
  {
    id: 2,
    title: "Our Products",
    image: ProductsImage,
    link: "/faqs/products",
  },
  { id: 3, title: "Rentals", image: RentalsImage, link: "/faqs/rentals" },
  {
    id: 4,
    title: "Payment Options",
    image: PaymentsImage,
    link: "/faqs/payment",
  },
];

const FaqCard = ({ title, link, image }) => {
  return (
    <Link
      to={link}
      className="flex flex-col items-center justify-center w-full transition duration-500 ease-in-out hover:translate-y-5"
    >
      <div>
        <img src={image} alt={title} className="rounded-[10px]" />
        <h2 className="mt-7 text-xl lg:mt-10 lg:text-[28px] text-reedditeal font-bold text-center">
          {title}
        </h2>
      </div>
    </Link>
  );
};

export default function FaqsSection() {
  return (
    <FaqsContainer>
      <div className="mt-16 grid grid-cols-1 gap-y-20 md:grid-cols-2 md:gap-x-10 lg:grid-cols-4">
        {faqs.map((item, index) => (
          <FaqCard
            key={index}
            title={item.title}
            link={item.link}
            image={item.image}
          />
        ))}
      </div>
    </FaqsContainer>
  );
}
